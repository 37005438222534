import React, { useRef } from 'react';
import { IconButton, Badge, Tooltip } from '@mui/material';
import { FileUpload } from '@mui/icons-material';
import { renderSnackbar } from 'utils';
import { useTranslation } from 'react-i18next';
import Avatar from '@mui/material/Avatar';
import 'react-image-crop/dist/ReactCrop.css';
import CropImagePopup from './CropImagePopup';

interface UploadImageProps {
  onUpload: any;
  size?: number;
  imageUrl: string;
  component: React.FC<any>;
  checkSize?: boolean;
  disabled?: boolean;
  tooltip?: string;
}
const MAX_SIZE_IMAGE = 2097152; //2MB

const UploadImage = ({
  onUpload,
  size = 70,
  imageUrl,
  component,
  checkSize = true,
  disabled = false,
  tooltip = '',
}: UploadImageProps) => {
  const imageInputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation(['upload_image']);
  const [defaultImage, setDefaultImage] = React.useState<any>(null);
  const IconComponent = component;
  
  return (
    <>
      <Tooltip title={tooltip}>
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={
            <IconButton
              style={{
                color: '#000',
                backgroundColor: '#fff',
                borderRadius: 50,
                padding: 3,
              }}
              disabled={disabled}
            >
              <FileUpload />
            </IconButton>
          }
          onClick={() => {
            imageInputRef.current?.click();
          }}
        >
          {imageUrl ? (
            <Avatar
              style={{
                height: size,
                width: size,
                border: '1px solid gray',
              }}
              imgProps={{
                sx: {
                  objectFit: 'contain',
                },
              }}
              src={imageUrl}
            />
          ) : (
            <IconComponent style={{ width: size, height: size }} />
          )}
        </Badge>
      </Tooltip>
      <input
        type="file"
        id="avatar"
        name="avatar"
        accept="image/png, image/jpeg"
        ref={imageInputRef}
        onChange={(e: any) => {
          const newImageFile = e.target.files[0];
          if (!newImageFile) return;
          if (['image/png', 'image/jpeg'].indexOf(newImageFile.type) === -1) {
            renderSnackbar([t('upload_image:file_not_suitable')]);
            return;
          }
          if (checkSize && newImageFile.size > MAX_SIZE_IMAGE) {
            renderSnackbar([t('upload_image:file_too_big')]);
            return;
          }

          const reader = new FileReader();
          reader.addEventListener('load', () => {
            const imageSrc = reader.result?.toString() || '';
            setDefaultImage(imageSrc);
          });
          reader.readAsDataURL(e.target.files[0]);
        }}
        hidden
      />
      {defaultImage && (
        <CropImagePopup
          imageUrl={defaultImage}
          onClose={() => {
            setDefaultImage(null);
            if (imageInputRef.current) {
              imageInputRef.current.value = '';
            }
          }}
          onUpload={onUpload}
        />
      )}
    </>
  );
};
export default UploadImage;
