import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { change, Field, FieldArray, InjectedFormProps, reduxForm } from 'redux-form';

import { Button, Grid, Typography } from '@mui/material';
import { get } from 'api';
import { CheckboxInput, Loader } from 'components';
import SelectInput from 'components/select-input';
import { renderSnackbar } from '../../../utils';
import OutlinedSection from '../outlined-section';
import useStyles from '../styles';
import SubmitButton from '../submit-btn';
import { required } from '../validators';
import { getStep3Fields } from './helper';
import HazardCodes from './hazard-codes';
import PrecautionsCodes from './precaution-codes';
import HazardsSectionPreview from 'components/hazards-section-preview/HazardsSectionPreview';
import { isMobile } from 'utils/helper';
import { CustomFormProps } from '../utils';
import SaveAsButton from 'components/save-as-btn';

interface HazardsCodesFormProps extends CustomFormProps {
  country?: any;
  overwrittenHazardStatements?: any;
  pushToArray?: any;
  formEdited?: boolean;
}

const HazardsCodesForm = (props: HazardsCodesFormProps & InjectedFormProps<{}, HazardsCodesFormProps>) => {
  const classes = useStyles();
  const {
    handleNext,
    country,
    overwrittenHazardStatements,
    dirty,
    pushToArray,
    formEdited,
    handleCopy,
  } = props;
  const { t } = useTranslation(['sds_authoring']);
  const dispatch = useDispatch();
  const translations = useSelector(
    (state: any) => state.pageReducer.translations
  );
  const languageCode = useSelector(
    (state: any) => state.form.section0Form?.values?.language
  );
  const countryCode = useSelector(
    (state: any) => state.form.section0Form?.values?.sds_country
  );

  const form = useSelector((state: any) => state.form.hazardsCodesForm);
  const [statements, setStatements] = React.useState([]);
  const [pCodesStatements, setPCodesStatements] = React.useState([]);
  const [showPreview, setShowPreview] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [classifications, setClassifications] = React.useState([]);

  const handleSubmit = () => {
    const codesWithoutStatements = form?.values?.precautionsCodes
      ?.filter(
        (el: any) =>
          el?.statements?.includes('...') &&
          !overwrittenHazardStatements[el?.id]
      )
      ?.map((el: any) => el.statement_code);
    if (codesWithoutStatements?.length > 0) {
      renderSnackbar([
        `${t(
          'sds_authoring:incomplete_p_codes'
        )} (${codesWithoutStatements.join(',')})`,
      ]);
      return;
    }
    if (dirty || formEdited) {
      const hazardCodesIds = form?.values?.hazardCodes?.map((el: any) => el.id);
      const precautionsCodesIds = form?.values?.precautionsCodes?.map(
        (el: any) => el.id
      );
      handleNext({
        hazards_information: {
          ...form?.values,
          overwritten_statements: overwrittenHazardStatements,
          codes_input: hazardCodesIds,
          precautions_codes_input: precautionsCodesIds,
        },
      });
    } else {
      handleNext(null);
    }
  };
  const getSignalWord = () => {
    const signalWords = form?.values?.hazardCodes?.map(
      (el: any) => el.signal_word
    );
    return signalWords?.includes('Danger')
      ? translations.layout.signal_word_danger
      : signalWords?.includes('Warning')
      ? translations.layout.signal_word_warning
      : translations.layout.signal_word_none;
  };

  const getGHSPictograms = () => {
    return Array.from(
      new Set(form?.values?.hazardCodes?.map((el: any) => el.pictogram_url))
    );
  };

  React.useEffect(() => {
    setLoading(true);
    const getClassificationsRequest = get(
      '/sdsAuthoring/utils/classifications/',
      {
        language_code: languageCode,
        country_code: countryCode,
      }
    );
    getClassificationsRequest.then((response) => {
      if (response.status === 200) {
        setClassifications(response.data);
        const classification = response.data.find(
          (el: any) => el.is_default === true
        )?.id;
        dispatch(change('hazardsCodesForm', 'classification', classification));
        dispatch(
          change('hazardsCodesForm', 'labeling_classification', classification)
        );
      }
    });
    const getGHSStatementsRequest = get('/sdsAuthoring/utils/ghsStatements/', {
      country: country,
      language_code: languageCode,
    });
    getGHSStatementsRequest.then((response) => {
      if (response.status === 200) {
        setStatements(response.data);
      }
    });
    setLoading(false);
    const getPrecautionsStatementsRequest = get(
      '/sdsAuthoring/utils/pCodesStatements/',
      {
        language_code: languageCode,
      }
    );
    getPrecautionsStatementsRequest.then((response) => {
      if (response.status === 200) {
        setPCodesStatements(response.data);
      }
    });
    setLoading(false);
  }, [languageCode]);

  const sections = getStep3Fields();

  const fieldsArrayProps = {
    pushToArray: pushToArray,
    precautionsCodes: form?.values?.precautionsCodes,
  };

  return (
    <div className={classes.formWrapper}>
      {loading ? (
        <Loader />
      ) : showPreview ? (
        <>
          <Button
            variant="outlined"
            sx={{ marginBottom: '20px' }}
            onClick={() => setShowPreview(false)}
          >
            {t('sds_authoring:hide_preview')}
          </Button>
          <HazardsSectionPreview />
        </>
      ) : (
        <>
          {(dirty || formEdited) && (
            <Button
              variant="contained"
              sx={{ marginBottom: '20px' }}
              onClick={() => setShowPreview(true)}
            >
              {t('sds_authoring:show_preview')}
            </Button>
          )}
          <Typography variant="h5">
            {translations.headers['2_header_hazard_identification']}
          </Typography>
          <form onSubmit={handleSubmit}>
            <OutlinedSection
              title={
                translations.headers[
                  '2_1_header_classification_of_the_substance'
                ]
              }
            >
              <Grid item xs={12}>
                <Field
                  name="classification"
                  component={SelectInput}
                  validate={[required]}
                  options={classifications}
                  valueField={'id'}
                  labelField={'literal'}
                  label={
                    translations.sections.classification
                      ? translations.sections.classification
                      : t('fields:classification')
                  }
                  style={isMobile() ? { width: '100%' } : { minWidth: '300px' }}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="is_not_classified_as_hazardous"
                  component={CheckboxInput}
                  label={t('sds_authoring:not_classified_as_hazardous')}
                />
              </Grid>
              <Grid item xs={12}>
                <FieldArray
                  name="hazardCodes"
                  statements={statements}
                  component={HazardCodes}
                  {...fieldsArrayProps}
                />
              </Grid>
            </OutlinedSection>

            {sections.map((section, index) => {
              if (
                section.title ===
                translations.headers['2_2_header_label_elements']
              ) {
                return (
                  <OutlinedSection
                    title={translations.headers['2_2_header_label_elements']}
                  >
                    <Grid item xs={12}>
                      {form?.values?.is_not_classified_as_hazardous ? null : (
                        <>
                          <Typography>
                            {translations.sections.signal_word}:
                            {getSignalWord()}
                          </Typography>
                          <div className={classes.pictogramsWrapper}>
                            {getGHSPictograms()?.map((el: any) => (
                              el ?
                              <img
                                src={el}
                                alt={'GHS pic.'}
                                className={classes.pictogram}
                              /> : null
                            ))}
                          </div>
                        </>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name="labeling_classification"
                        component={SelectInput}
                        validate={[required]}
                        valueField={'id'}
                        labelField={'literal'}
                        options={classifications}
                        label={
                          translations.sections.labeling_classification
                            ? translations.sections.labeling_classification
                            : t('sds_authoring:labeling_classification')
                        }
                        style={isMobile() ? { width: '100%' } : { minWidth: '300px' }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FieldArray
                        name="precautionsCodes"
                        component={PrecautionsCodes}
                        statements={pCodesStatements}
                      />
                    </Grid>
                    {section.fields.map((field, index) => {
                      return (
                        <Grid key={index} item xs={field.xs}>
                          <Field
                            validate={field.validate ?? null}
                            name={field.name}
                            component={field.component}
                            label={field.label}
                            multiline={field.multiline ?? null}
                            options={field.options ?? null}
                            required={field.required ?? null}
                            valueField={field.valueField ?? null}
                            labelField={field.labelField ?? null}
                            classes={{ root: classes.textInput }}
                          />
                        </Grid>
                      );
                    })}
                  </OutlinedSection>
                );
              }
              return (
                <OutlinedSection key={index} title={section.title}>
                  {section.fields.map((field, index) => {
                    return (
                      <Grid key={index} item xs={field.xs}>
                        <Field
                          validate={field.validate ?? null}
                          name={field.name}
                          component={field.component}
                          label={field.label}
                          multiline={field.multiline ?? null}
                          options={field.options ?? null}
                          required={field.required ?? null}
                          valueField={field.valueField ?? null}
                          labelField={field.labelField ?? null}
                          classes={{ root: classes.textInput }}
                        />
                      </Grid>
                    );
                  })}
                </OutlinedSection>
              );
            })}
            <SubmitButton handleSubmit={handleSubmit} />
            <SaveAsButton handleSubmit={handleCopy} />

          </form>
        </>
      )}
    </div>
  );
};

function mapStateToProps(state: any) {
  if (
    state.pageReducer.sdsInfo?.hazards_information &&
    !state.form.hazardsCodesForm?.values
  ) {
    return {
      initialValues: {
        hazardCodes: state.pageReducer.sdsInfo?.hazards_information?.codes
          ? state.pageReducer.sdsInfo.hazards_information.codes
          : [],
        precautionsCodes: state.pageReducer.sdsInfo?.hazards_information
          ?.precautions_codes
          ? state.pageReducer.sdsInfo.hazards_information.precautions_codes
          : [],
        primary_routes_of_exposure:
          state.pageReducer.sdsInfo?.hazards_information
            ?.primary_routes_of_exposure,
        hazard_components:
          state.pageReducer.sdsInfo?.hazards_information?.hazard_components,
        contains: state.pageReducer.sdsInfo?.hazards_information?.contains,
        supplemental_label_elements:
          state.pageReducer.sdsInfo?.hazards_information
            ?.supplemental_label_elements,
        annex_restrictions:
          state.pageReducer.sdsInfo?.hazards_information?.annex_restrictions,
        special_packing_requirements:
          state.pageReducer.sdsInfo?.hazards_information
            ?.special_packing_requirements,
        touch_hazard_warning:
          state.pageReducer.sdsInfo?.hazards_information?.touch_hazard_warning,
        hazards_additional_information:
          state.pageReducer.sdsInfo?.hazards_information
            ?.hazards_additional_information,
        hazards_results_of_pbt_and_vpvb_assessment:
          state.pageReducer.sdsInfo?.hazards_information
            ?.hazards_results_of_pbt_and_vpvb_assessment,
        hazards_potential_health_effects_accute:
          state.pageReducer.sdsInfo?.hazards_information
            ?.hazards_potential_health_effects_accute,
        hazards_potential_health_effects:
          state.pageReducer.sdsInfo?.hazards_information
            ?.hazards_potential_health_effects,
        long_term_hazards:
          state.pageReducer.sdsInfo?.hazards_information?.long_term_hazards,
        hazards_outside_classification:
          state.pageReducer.sdsInfo?.hazards_information
            ?.hazards_outside_classification,
        other_hazards:
          state.pageReducer.sdsInfo?.hazards_information?.other_hazards,
        is_not_classified_as_hazardous:
          state.pageReducer.sdsInfo?.hazards_information
            ?.is_not_classified_as_hazardous,
        classification: state.pageReducer.sdsInfo?.hazards_information
          ?.classification
          ? state.pageReducer.sdsInfo?.hazards_information?.classification
          : state.pageReducer.countriesSettings[
              state.form?.productDescriptionForm?.values?.sds_country
            ]?.classification,
        labeling_classification:
          state.pageReducer.sdsInfo?.hazards_information
            ?.labeling_classification,
      },
      country: state.form?.productDescriptionForm?.values?.sds_country
        ? state.form.productDescriptionForm.values.sds_country
        : state.pageReducer?.sdsInfo?.sds_country,
      overwrittenHazardStatements:
        state.pageReducer.overwrittenHazardStatements,
      statementsEdited: state.pageReducer.statementsEdited,
    };
  }
  return {
    country: state.form?.productDescriptionForm?.values?.sds_country
      ? state.form.productDescriptionForm.values.sds_country
      : state.pageReducer?.sdsInfo?.sds_country,
    overwrittenHazardStatements: state.pageReducer.overwrittenHazardStatements,
    statementsEdited: state.pageReducer.statementsEdited,
  };
}

export default connect(mapStateToProps)(
  reduxForm<{}, HazardsCodesFormProps>({
    form: 'hazardsCodesForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
  })(HazardsCodesForm)
);
