import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    paper: {
        maxWidth: '70%',
        maxHeight: '90vh',
        minHeight: '50vh',
        minWidth: '500px',
    },
    dialogContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 30,
        minHeight: '60vh',
        maxHeight: '90vh',
    },
    actionsWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 30
    }
}));

export default useStyles;