import React, { useRef, useState } from 'react';
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { useDebounceEffect } from './useDebounceEffect';
import { canvasPreview } from './canvasPreview';
import DesktopDialog from 'components/dialog/Dialog';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import useStyles from './styles';

const CropImagePopup = ({
  imageUrl,
  onClose,
  onUpload,
}: {
  imageUrl: string;
  onClose: () => void;
  onUpload: (blob: Blob) => void;
}) => {
  const classes = useStyles();

  const { t } = useTranslation(['popups']);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const dialogContentRef = useRef<HTMLDivElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [preview, setPreview] = useState<boolean>(false);
  const [topPosition, setTopPosition] = useState<number>(0);

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    const { width, height } = e.currentTarget;
    setCrop({
      width: width,
      height: height,
      unit: 'px',
      x: 0,
      y: 0,
    });
    if (dialogContentRef) {
      const parentHeight = dialogContentRef.current?.offsetHeight || 0;
      if (parentHeight < height)
      setTopPosition((height - parentHeight) /2 + 10 );
    }
  }
  const onSaveImage = async () => {
    const image = imgRef.current;
    const previewCanvas = previewCanvasRef.current;
    if (!image || !previewCanvas || !completedCrop) {
      throw new Error('Crop canvas does not exist');
    }
    previewCanvas.toBlob((blob: Blob | null) => {
      if (blob) {
        const file = new File([blob], 'image.png', { type: 'image/png' });
        onUpload(file);
      }
    }, 'image/png');
    onClose();
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
      }
    },
    100,
    [completedCrop]
  );

  return (
    <DesktopDialog
      onClose={() => onClose()}
      maxWidth={'lg'}
      title={t('popups:crop_image')}
      classes={{ paper: classes.paper }}
      actions={
        <>
          <div className={classes.actionsWrapper}>
            {preview ? (
              <Button
                onClick={() => {
                  setPreview(false);
                }}
                variant="outlined"
              >
                {t('actions:back')}
              </Button>
            ) : (
              <Button
                onClick={() => {
                  setPreview(true);
                }}
                variant="outlined"
              >
                {t('sds_authoring:show_preview')}
              </Button>
            )}
            <Button
              variant="contained"
              onClick={() => {
                onSaveImage();
              }}
            >
              {t('actions:save')}
            </Button>
          </div>
        </>
      }
      DialogContentProps={{
        className: classes.dialogContent,
        ref: dialogContentRef
      }}
    >
      <div className="">
      <ReactCrop
        crop={crop}
        onChange={(_, percentCrop) => setCrop(percentCrop)}
        onComplete={(c) => setCompletedCrop(c)}
        style={{
          display: preview ? 'none' : 'block',
          top: topPosition,
        }}
        minWidth={50} 
        minHeight={50} 
      >
        <img ref={imgRef} alt="Crop me" src={imageUrl} onLoad={onImageLoad} />
      </ReactCrop>
      {!!completedCrop && (
        <div
          style={{
            display: !preview ? 'none' : 'block',
          }}
        >
          <canvas
            ref={previewCanvasRef}
            style={{
              border: '1px solid black',
              objectFit: 'contain',
              width: completedCrop.width,
              height: completedCrop.height,
            }}
          />
        </div>
      )}
      </div>
    </DesktopDialog>
  );
};

export default CropImagePopup;
